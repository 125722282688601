import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
  ...baseEnvironment,

  isProduction: true,

  isDevelopment: false,

  ctProjectKey: 'cz-d2c-prd-danone',

  ctClientId: 'TpiVdlhhDctT_SeTn1kgdSBj',

  ctClientSecret: '2VX0i7rUKiSI6MudE0LP2MYHo0TIVGqd',

  defaultChannelId: '341d719b-31d6-4796-9d24-d0eb8aff54e1',

  sentryDSN: 'https://cf4515428983f18596ab800d415cc5d6@o4506399937200128.ingest.sentry.io/4506400363184128',

  adyen: {
    clientKey: 'live_L3L7FCL7DVAU7AJJU66UZDMDTYD2IVWJ',
    environment: 'live',

    googlePay: {
      environment: 'PRODUCTION',
      gatewayMerchantId: 'DanoneNutricia',
      merchantName: 'Nutricia Nutriklub',
      merchantId: 'BCR2DN6TT6SZHQQD',
    },
  },
}
